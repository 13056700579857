<template>
  <div class="container-fluid">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_LOCATIONS)"
          :to="{ name: 'List Locations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-map-big"></i>
            </span>
            <small>{{ $t("COMMON.LOCATIONS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS)"
          :to="{ name: 'List Organizations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-building"></i>
            </span>
            <small>{{ $t("COMMON.ORGANIZATIONS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLERS)"
          :to="{ name: 'List Resellers' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-shop"></i>
            </span>
            <small>{{ $t("COMMON.RESELLERS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_USERS)"
          :to="{ name: 'List Users' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-single-02"></i>
            </span>
            <small>{{ $t("COMMON.USERS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ROLES)"
          :to="{ name: 'List Roles' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-hat-3"></i>
            </span>
            <small>{{ $t("COMMON.ROLES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_IMPORTS)"
          :to="{ name: 'List Imports' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-box-2"></i>
            </span>
            <small>{{ $t("COMMON.IMPORTS") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
